<template>
	<div style="background-color: white;min-height: 100vh;" v-if="info">
		
		<van-row type="flex" justify="center" class="from-bottom-button">
		    <van-col style="margin: 30px 0 10px 0;text-align: center;color: #009900;font-size: 18px;">
				<span>恭喜您，付款成功！</span>
			</van-col>
		</van-row>
		
		<van-row type="flex" justify="center" class="from-bottom-button">
		    <van-col style="margin: 10px 0;font-size: 13px;line-height: 40px;">
				<div>我的订单：{{info.goods_info_json.moldbaby_name}}</div>
				<div>订单编号：{{info.order_sn}}</div>
				<div>付款金额：<span style="color: red;">￥{{info.pay_price}}</span></div>
				<div>付款时间：{{info.paytime_text}}</div>
			</van-col>
		</van-row>
		
		<van-row type="flex" justify="center" class="from-bottom-button">
		    <van-col style="margin: 10px 0;">
				<van-button type="info" @click="toDetail()" >返回商品详情</van-button>
				<van-button type="info" @click="toactivity()" style="margin-left: 20px;">返回活动详情</van-button>
			</van-col>
		</van-row>
	</div>
</template>

<script>
import {
        Field,
        Row,
        Col,
        Cell,
        CellGroup,
        Picker,
        Popup,
        DatetimePicker,
        Uploader,
        SwitchCell,
        Checkbox,
        Button,
        Toast,
		Stepper,
		Slider,
		Collapse,
		CollapseItem,
		Loading
    } from "vant";

import {
    user_marketing_hot_orderdetail,
} from "@/request/api";


export default {
	components: {
	    [Field.name]: Field,
	    [Row.name]: Row,
	    [Col.name]: Col,
	    [Cell.name]: Cell,
	    [CellGroup.name]: CellGroup,
	    [Picker.name]: Picker,
	    [Popup.name]: Popup,
	    [DatetimePicker.name]: DatetimePicker,
	    [Uploader.name]: Uploader,
	    [SwitchCell.name]: SwitchCell,
	    [Checkbox.name]: Checkbox,
	    [Button.name]: Button,
		[Stepper.name]: Stepper,
		[Slider.name]: Slider,
		[Collapse.name]: Collapse,
		[CollapseItem.name]: CollapseItem,
		[Loading.name]: Loading
	},
	data () {
		return {
			collapse_address: ['1'],
			commit_loading: false,
			address: '',
			order_id:0,
			info:false
		}
	},
    mounted: function() {
        this.order_id = this.$route.query.id;
        this.initData();
    },
	methods: {
        initData(){
            user_marketing_hot_orderdetail({rid:this.order_id}).then(res=>{
                if(res.code==1){
                    this.info = res.data;
                }else{
                    this.$dialog({title:'提示',message:res.msg}).then(g=>{
                        this.$router.go(-1);
                    });
                }
            }).catch(error=>{
                this.$dialog({title:'提示',message:"访问出错"}).then(g=>{
                    this.$router.go(-1);
                });
            });
        },
		toDetail(){
            this.$router.replace({path:'/activity/marketing/hot/detail',query:{id:this.info.goods_id}})
//			this.$router.push('/activity/marketing/group/detail')
		},
		toactivity(){
            this.$router.replace({path:'/activity',query:{id:this.info.activity_id}})
		}
	}
}
</script>

<style scoped lang="less">
</style>
