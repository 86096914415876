import { render, staticRenderFns } from "./pay_success.vue?vue&type=template&id=5992a1d2&scoped=true"
import script from "./pay_success.vue?vue&type=script&lang=js"
export * from "./pay_success.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5992a1d2",
  null
  
)

export default component.exports